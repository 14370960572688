import { useState, useEffect } from 'react';
import { useApolloClient, useQuery, NetworkStatus } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { parseISO } from 'date-fns';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
// GraphQL Queries and Types
import query from '../graphql/queries/UserChatMeetingsPaginated.graphql';
import { UserChatMeetingsPaginated, UserChatMeetingsPaginatedVariables } from '../types';
import { MEETING_TYPES } from '../constants';
import { formatInTimeZone } from '../utils';

export interface MeetingsListContainerProps {
  chatId: string;
  processing: boolean;
  onCancel: () => void;
  onSubmit: (ids: string[]) => void;
}

export const MeetingsListContainer: React.VFC<MeetingsListContainerProps> = ({
  chatId,
  processing,
  onCancel,
  onSubmit,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const client = useApolloClient();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [filterTextValue, setFilterTextValue] = useState('');
  const [filterStatusValue, setFilterStatusValue] = useState<
    'all-meetings' | 'in-chat' | 'not-added'
  >('all-meetings');

  const ROWS_PER_PAGE = 10;

  const { data, fetchMore, refetch, networkStatus } = useQuery<
    UserChatMeetingsPaginated,
    UserChatMeetingsPaginatedVariables
  >(query, {
    variables: { chatId, page: 1, perPage: ROWS_PER_PAGE, search: '', isInChat: null },
    notifyOnNetworkStatusChange: true,
  });
  /* #endregion */

  /* #region  Helpers */
  const items = data?.userChatMeetingsPaginated?.objects ?? [];
  const isEveryItemInChat = items.every((item) => item.isInChat);
  const availableItems = items.filter((item) => !item.isInChat);
  const selectAllChecked = availableItems.length === selectedItems.length;
  const selectAllIndeterminate = selectedItems.length > 0 && !selectAllChecked;
  /* #endregion */

  /* #region  Handlers */
  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    const term = value ? value.toLowerCase() : '';
    refetch({ page: 1, perPage: ROWS_PER_PAGE, search: term, chatId });
  }, 1000);

  const handleChangeFilterTextValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setFilterTextValue(term);
    handleSearchDebounced(term);
  };

  const handleFilterStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!['all-meetings', 'in-chat', 'not-added'].includes(event.target.value as string)) {
      throw new Error('Invalid filter status value');
    }

    const value = event.target.value as 'all-meetings' | 'in-chat' | 'not-added';
    setFilterStatusValue(value);
    refetch({
      chatId,
      page: 1,
      perPage: ROWS_PER_PAGE,
      search: filterTextValue,
      isInChat: value === 'in-chat' ? true : value === 'not-added' ? false : null,
    });
  };

  const handleClearFilterValue = () => {
    setFilterTextValue('');
    refetch({ page: 1, perPage: ROWS_PER_PAGE, search: '', chatId });
  };

  const handleToggleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedItems([]);
    } else {
      const meetingIds = availableItems?.map((item) => item.meeting.id) ?? [];
      setSelectedItems(meetingIds);
    }
  };

  const handleSelectMeeting = (id: string) => () => {
    const isItemSelected = selectedItems.indexOf(id) !== -1;
    setSelectedItems((prev) =>
      isItemSelected ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        chatId,
        page: (data?.userChatMeetingsPaginated?.page ?? 1) + 1,
        perPage: ROWS_PER_PAGE,
        search: filterTextValue,
        isInChat:
          filterStatusValue === 'in-chat' ? true : filterStatusValue === 'not-added' ? false : null,
      },
    });
  };

  const handleSubmit = () => {
    onSubmit(selectedItems);
  };
  /* #endregion */

  useEffect(() => {
    // Clear cache when unmounting
    return () => {
      client.cache.evict({ fieldName: 'userChatMeetingsPaginated' });
      client.cache.gc();
    };
  }, [client.cache]);

  /* #region  Render Helpers */
  const isLoadingInitial = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isSettingVariables = networkStatus === NetworkStatus.setVariables;
  const isRefetching = networkStatus === NetworkStatus.refetch || isSettingVariables;
  const hasMore = data?.userChatMeetingsPaginated?.hasNext ?? false;
  const availableObjectsCount = data?.userChatMeetingsPaginated?.numObjects ?? 0;
  const requestedObjectsCount = data?.userChatMeetingsPaginated?.objects?.length ?? 0;
  /* #endregion */

  return (
    <div className={styles.root}>
      {isLoadingInitial || processing ? (
        <Box display="flex" justifyContent="center" my={8}>
          <CircularProgress />
        </Box>
      ) : (
        <div className={styles.container}>
          <div className={styles.filters}>
            <TextField
              autoFocus
              fullWidth
              size="small"
              variant="filled"
              placeholder="Search meetings"
              disabled={data === undefined}
              value={filterTextValue}
              onChange={handleChangeFilterTextValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {!!isRefetching ? (
                      <CircularProgress size={18} />
                    ) : (
                      <>
                        {!!filterTextValue && (
                          <IconButton size="small" onClick={handleClearFilterValue}>
                            <CloseIcon fontSize="small" color="primary" />
                          </IconButton>
                        )}
                      </>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <FormControl size="small" variant="filled" className={styles.formControl}>
              <Select
                disabled={data === undefined}
                value={filterStatusValue}
                onChange={handleFilterStatusChange}
              >
                <MenuItem value="all-meetings">Any status</MenuItem>
                <MenuItem value="in-chat">In chat</MenuItem>
                <MenuItem value="not-added">Not added</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <Box mb={2}>
              <TableContainer>
                <Table padding="checkbox" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          size="small"
                          color="primary"
                          disabled={isEveryItemInChat}
                          checked={selectAllChecked}
                          indeterminate={selectAllIndeterminate}
                          onChange={handleToggleSelectAll}
                        />
                      </TableCell>
                      <TableCell>Meeting</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {items.map((item) => {
                      let meetingType: string | null = null;

                      for (const category of MEETING_TYPES) {
                        const element = category.elements.find(
                          (el) => el.value === item.meeting.meetingType,
                        );
                        if (element) {
                          meetingType = element.label;
                          break;
                        }
                      }

                      return (
                        <TableRow key={item.meeting.id}>
                          <TableCell width="auto">
                            <Checkbox
                              size="small"
                              color="primary"
                              disabled={item.isInChat}
                              checked={item.isInChat || selectedItems.includes(item.meeting.id)}
                              onChange={handleSelectMeeting(item.meeting.id)}
                            />
                          </TableCell>
                          <TableCell width="auto">{item.meeting.title}</TableCell>
                          <TableCell width="auto">
                            {item.meeting.startedAt
                              ? formatInTimeZone(parseISO(item.meeting.startedAt), 'PPpp')
                              : ''}
                          </TableCell>
                          <TableCell>{meetingType || 'General'}</TableCell>
                          <TableCell>
                            <Chip
                              size="small"
                              color={item.isInChat ? 'primary' : 'default'}
                              label={item.isInChat ? 'In Chat' : 'Not added'}
                              classes={{ colorPrimary: styles.chip }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {items.length === 0 && !filterTextValue && !isSettingVariables && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Box my={8}>
                            <Typography align="center">
                              {!filterTextValue &&
                              (!filterStatusValue || filterStatusValue === 'all-meetings')
                                ? 'No meetings found matching the criteria.'
                                : 'Currently, there are no meetings available.'}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className={styles.tableFooter}>
                <Typography variant="caption" color="textSecondary">
                  {selectedItems.length} selected
                </Typography>

                {hasMore && !isRefetching && (
                  <Button
                    disableElevation
                    color="default"
                    variant="outlined"
                    disabled={isRefetching}
                    onClick={handleFetchMore}
                    endIcon={
                      isLoadingMore && <CircularProgress size={16} color="primary" thickness={3} />
                    }
                  >
                    <Typography noWrap component="span" variant="body2">
                      Load more
                    </Typography>
                  </Button>
                )}

                {!!availableObjectsCount && (
                  <Typography
                    noWrap
                    color="textSecondary"
                    variant="body2"
                    component="span"
                    align="right"
                  >
                    {requestedObjectsCount} of {availableObjectsCount}
                  </Typography>
                )}
              </div>
            </Box>

            {!items.length && !!filterTextValue && !isRefetching && !isSettingVariables && (
              <Box mt={4} mb={8}>
                <Typography align="center">No results found for "{filterTextValue}".</Typography>
              </Box>
            )}

            <div className={styles.actions}>
              <Button variant="outlined" onClick={onCancel}>
                <Typography noWrap component="span" variant="body1">
                  Cancel
                </Typography>
              </Button>
              <Tooltip
                arrow
                placement="top"
                title={selectedItems.length ? '' : 'Select at least one meeting'}
              >
                <span>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    disabled={!selectedItems.length}
                    onClick={handleSubmit}
                  >
                    <Typography noWrap component="span" variant="body1">
                      Add Meetings
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(4, 1),
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  chip: {
    color: '#29A667',
    background: 'rgba(41, 166, 103, 0.2)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(1),
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 0),
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  table: {
    '& td': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '& th': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '& td:not(:nth-child(1))': {
      whiteSpace: 'nowrap',
    },
    '& td:nth-child(2)': {
      width: '50%',
    },
    '& td:nth-child(4)': {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflowX: 'auto',
      '& .MuiTableCell-root': {
        padding: theme.spacing(1.5),
        fontSize: theme.typography.body2.fontSize,
      },
      '& .MuiTableCell-head': {
        whiteSpace: 'nowrap',
      },
    },
  },
  tableFooter: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    '& > span': {
      flex: 1,
    },
  },
}));

export default MeetingsListContainer;
