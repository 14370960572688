import SparkMD5 from 'spark-md5';
import { FormEvent, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { createUpload, UpChunk } from '@mux/upchunk';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import { useDropzone, FileRejection } from 'react-dropzone';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialLink from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import AttachmentIcon from '@material-ui/icons/AttachmentSharp';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteSharp';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import RefreshIcon from '@material-ui/icons/Refresh';
// Sembly UI
import {
  convertSecondsToString,
  formatInTimeZone,
  LanguageSelects,
  TransitionSlide,
} from '@sembly-ui';
// GQL Types and Queries
import initiateStorageUploadQuery from '@shared/queries/InitiateStorageUpload.graphql';
import myMeetingsQuery from '@shared/queries/MyMeetingOverviewsPaginated.graphql';
import submitMeetingAudioQuery from '@shared/queries/SubmitMeetingAudio.graphql';
import uploadMeetingAccessQuery from '@shared/queries/UploadMeetingAccess.graphql';
import { InitiateStorageUpload, InitiateStorageUploadVariables } from '@gql-types/InitiateStorageUpload'; // prettier-ignore
import { SubmitMeetingAudio, SubmitMeetingAudioVariables } from '@gql-types/SubmitMeetingAudio';
import { UploadMeetingAccess } from '@gql-types/UploadMeetingAccess';
import { Languages, PlanIDEnum } from '@gql-types';
// App Shared Icons
import UploadIcon from '@shared/icons/Upload';
// App Shared
import LimitExceededMessage from '@shared/dialogs/LimitExceededMessage';
import { ga, graphErrorHorsemen } from '@shared/utils';
import { useUserContext } from '@shared/hooks';

export interface UploadMeetingModalProps {
  onClose: () => void;
  onUpload: (title: string) => void;
}

export const UploadMeetingModal: React.VFC<UploadMeetingModalProps> = ({ onClose, onUpload }) => {
  const formats = [
    '.mp3',
    '.wav',
    '.aiff',
    '.aac',
    '.ogg',
    '.wma',
    '.flac',
    '.m4a',
    '.mp2',
    '.mp4',
    '.asf',
    '.wmv',
  ];
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const user = useUserContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [errors, setErrors] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [hash, setHash] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState(0);
  const [isAttachmentDecoded, setIsAttachmentDecoded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUploadPaused, setIsUploadPaused] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [meetingLanguages, setMeetingLanguages] = useState<{
    primaryLang: Languages | null;
    additionalLang: Languages | null;
  }>({
    primaryLang: null,
    additionalLang: null,
  });

  const uploadRef = useRef<UpChunk>();

  const { data, loading } = useQuery<UploadMeetingAccess>(uploadMeetingAccessQuery, {
    fetchPolicy: 'network-only',
  });

  const [submitMeetingAudio] = useMutation<SubmitMeetingAudio, SubmitMeetingAudioVariables>(
    submitMeetingAudioQuery,
  );

  const [initiateStorageUpload] = useMutation<
    InitiateStorageUpload,
    InitiateStorageUploadVariables
  >(initiateStorageUploadQuery);
  /* #endregion */

  const resumeUploadDebounced = useDebouncedCallback(() => {
    uploadRef.current && uploadRef.current.resume();
  }, 2000);

  /* #region  Handlers */
  const handleDropAttachment = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setErrors('');
    setFileName('');
    setHash('');
    setFileSize(0);
    setMeetingTitle(title);

    if (fileRejections.length > 0) {
      const errorCodes = fileRejections.flatMap((file) => file.errors.map((err) => err.code));
      if (errorCodes.includes('too-many-files')) {
        setErrors(`Only one file can be uploaded at a time`);
        return;
      }
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setErrors(`File is larger than 500 MB`);
          }
          if (err.code === 'file-invalid-type') {
            setErrors(
              `File type must be wav, mp3, aiff, aac, ogg, wma, flac, m4a, mp2, mp4, asf or wmv`,
            );
          }
        });
      });
      return;
    }

    if (acceptedFiles.length > 0) {
      // additional check, File includes accepted Files formats
      if (formats.includes('.' + String(acceptedFiles[0].name.split('.').pop()?.toLowerCase()))) {
        setIsAttachmentDecoded(true);

        let currentChunk = 0;

        const blobSlice = File.prototype.slice;
        const uploadedFile = acceptedFiles[0]; // start audio duration
        const chunkSize = 20 * 1024 * 1024; // The size in bytes of the chunks to split the file for decoding
        const chunks = Math.ceil(uploadedFile.size / chunkSize);
        const arrayBuffer = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();

        // When the file has been successfully read
        fileReader.onload = function (event) {
          if (event?.target?.result && typeof event.target.result !== 'string') {
            arrayBuffer.append(event.target.result);
            currentChunk++;

            if (currentChunk < chunks) {
              loadNext();
            } else {
              setHash(arrayBuffer.end());
              setFileSize(uploadedFile.size);
              setFileName(acceptedFiles[0].name);
              setFiles(acceptedFiles);
              setIsAttachmentDecoded(false);
            }
          }
        };

        // In case that the file couldn't be read
        fileReader.onerror = function () {
          toast.error('An error occurred reading the file');
        };

        const loadNext = () => {
          const start = currentChunk * chunkSize;
          const end =
            start + chunkSize >= uploadedFile.size ? uploadedFile.size : start + chunkSize;
          // Read file as an ArrayBuffer, important !
          fileReader.readAsArrayBuffer(blobSlice.call(uploadedFile, start, end));
        };

        loadNext();
      } else {
        setErrors(
          `File type must be wav, mp3, aiff, aac, ogg, wma, flac, m4a, mp2, mp4, asf or wmv`,
        );
      }
    }
  };

  const handleChangeMeetingTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingTitle(e.target.value);
  };

  const handleClickOnDeleteAttachment = () => {
    setFileName('');
    setFiles([]);
  };

  const handleSubmitUploadMeeting = async (event: FormEvent) => {
    event.preventDefault();
    setIsProcessing(true);
    const { data } = await initiateStorageUpload({
      variables: {
        filename: fileName,
        md5: hash,
        size: fileSize,
      },
    });

    const storageResponse = data?.initiateStorageUpload?.storageUpload;
    const responseError = data?.initiateStorageUpload?.errors?.[0]?.messages?.[0];
    // The size of chunk in kb, should be 1/8 of fileSize, until reach 30mb. Then use 30mb.
    const chunkSizeCalc = (n: number) => (Math.ceil(n / 1024 / 2048) * 2048) / 8;
    const chunkSizeUpload = fileSize / 8 > 30 * 1024 * 1024 ? 30 * 1024 : chunkSizeCalc(fileSize);

    if (storageResponse?.url) {
      try {
        uploadRef.current = createUpload({
          endpoint: storageResponse.url, // Authenticated url
          file: files[0], // File object with your audio file property
          maxFileSize: 500 * 1024, // The maximum size of the file in kb of the input file to be uploaded
          chunkSize: chunkSizeUpload, // The size in kB of the chunks to split the file for uploading
        });

        // Subscribe to events
        uploadRef.current.on('error', (error) => {
          toast.error(error.detail);
        });

        uploadRef.current.on('progress', (progress) => {
          setProgress(Math.round(progress.detail));
        });

        uploadRef.current.on('success', () => {
          storageResponse?.id && getDetailedMeeting(storageResponse.id);
        });
      } catch (error) {
        setIsProcessing(false);
        toast.error('An error occurred uploading the file');
      }
    } else if (responseError) {
      setIsProcessing(false);
      setErrors(responseError);
    }

    const getDetailedMeeting = async (uploadId: string) => {
      const { data } = await submitMeetingAudio({
        variables: meetingLanguages.primaryLang
          ? {
              title: meetingTitle,
              storageUploadId: uploadId,
              languages: {
                primaryLanguage: meetingLanguages.primaryLang,
                additionalLanguage: meetingLanguages.additionalLang,
              },
            }
          : {
              title: meetingTitle,
              storageUploadId: uploadId,
            },
        refetchQueries: [myMeetingsQuery],
      });

      if (!data?.submitMeetingAudio?.meeting) {
        graphErrorHorsemen(data?.submitMeetingAudio?.errors);
        setIsProcessing(false);
        return false;
      }

      // register GA event
      ga.event({ category: 'New Meeting', action: 'Audio Uploaded' });

      setIsProcessing(false);
      onUpload(data.submitMeetingAudio.meeting.id);
      onClose();
    };

    return true;
  };

  const handleClose = (e?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    uploadRef.current && uploadRef.current.abort();
    onClose();
  };

  const handleUploadPause = () => {
    if (!uploadRef.current) return;
    setIsUploadPaused(true);
    if ((!!uploadRef.current as any).paused) return;
    uploadRef.current.pause();
  };

  const handleUploadResume = () => {
    if (!uploadRef.current) return;
    setIsUploadPaused(false);
    resumeUploadDebounced();
  };
  /* #endregion */

  /* #region  Render Helpers */
  const billingAccessType = user.data?.me?.billingAccess?.id;
  const isPersonalPlan = billingAccessType === PlanIDEnum.PERSONAL;
  const paymentCustomer = data?.me?.paymentCustomer;
  const creditsLeft = Math.round(paymentCustomer?.credits ?? 0);
  const billingCycleEnd = user.data?.me?.paymentCustomer?.nextFeaturesUsageResetDate || new Date();
  const renewDate = formatInTimeZone(billingCycleEnd, 'PP');
  const uploadUsageSec = paymentCustomer?.monthlyFeaturesUsage?.uploadRecordingsSecondsUsed ?? 0;
  const features = paymentCustomer?.plan?.features;
  const uploadLimitSec = features?.dynamicLimits?.uploadRecordingsSecondsLimit ?? 0;
  const uploadAvailableSec = uploadLimitSec - uploadUsageSec;
  const uploadCounterValue = uploadAvailableSec > 0 ? (uploadUsageSec * 100) / uploadLimitSec : 100;
  const isAuthorizedToUpload = uploadUsageSec < uploadLimitSec;
  const currentDate = formatInTimeZone(new Date(), 'PP p');
  const title = `Audio Upload | ${currentDate}`;
  const isDropzoneDisable = !isAuthorizedToUpload && creditsLeft <= 0;
  const languageSettings = user.data?.me?.workspace?.languageSettings;
  const primaryLang = languageSettings?.primaryLanguage || Languages.ENGLISH;
  const additionalLang = languageSettings?.additionalLanguage || null;

  // register dropzone hook
  const { getRootProps, open, getInputProps } = useDropzone({
    accept: { 'audio/*': formats },
    maxSize: 500 * 1024 * 1024,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled: isDropzoneDisable,
    onDrop: handleDropAttachment,
  });

  const renderDialogContent = () =>
    loading ? (
      <Box display="flex" justifyContent="center" p={10}>
        <CircularProgress size={24} color="primary" />
      </Box>
    ) : (
      <>
        <DialogTitle disableTypography className={styles.title}>
          <Typography variant="h5">
            Upload Audio File{' '}
            <Link
              color="textSecondary"
              target="_blank"
              rel="noopener noreferrer"
              title="Help"
              href="https://helpdesk.sembly.ai/hc/en-us/articles/8901816805649-Upload-Pre-recorded-Meetings"
              style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
            >
              <QuestionMarkIcon fontSize="inherit" />
            </Link>
          </Typography>
          <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.content}>
          {isProcessing ? (
            <Box textAlign="center" mt={2} className={styles.uploading}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box mr={1}>
                  {isUploadPaused ? (
                    <IconButton
                      className={styles.icon}
                      size="small"
                      aria-label="play"
                      onClick={handleUploadResume}
                    >
                      <RefreshIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={styles.icon}
                      size="small"
                      aria-label="pause"
                      onClick={handleUploadPause}
                    >
                      <PauseCircleOutlineIcon />
                    </IconButton>
                  )}
                </Box>
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={progress}
                  className={styles.linearProgress}
                  classes={{ bar: isUploadPaused ? styles.progressBarPaused : styles.progressBar }}
                />
                <Typography className={styles.progress}>{progress}%</Typography>
              </Box>
              <Typography className={styles.description}>
                <span className={styles.listItemName}>{fileName}</span>
                {isUploadPaused && <span className={styles.dot}>Paused</span>}
              </Typography>
            </Box>
          ) : (
            <Box className={styles.remaining}>
              <LinearProgress
                color="primary"
                variant="determinate"
                value={uploadCounterValue}
                className={styles.linearProgress}
                classes={{
                  bar: isAuthorizedToUpload ? styles.progressBar : styles.exceededBar,
                }}
              />
              <Typography className={styles.description}>
                {`${convertSecondsToString(
                  uploadLimitSec - uploadUsageSec,
                  `%value% upload %label% remaining. Plan limits will renew on ${renewDate}`,
                )}`}
              </Typography>
              {!isAuthorizedToUpload && (
                <Typography className={styles.warningBox}>
                  You can continue upload files using your workspace credits.{' '}
                  <MaterialLink
                    href="https://sembly-ai.zendesk.com/hc/en-us/articles/12899506141969"
                    color="inherit"
                    underline="always"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </MaterialLink>
                  <br />
                  Credits left: {creditsLeft}.
                </Typography>
              )}
            </Box>
          )}

          <form onSubmit={handleSubmitUploadMeeting}>
            <fieldset className={styles.formContainer}>
              {!isProcessing && (
                <>
                  <div {...getRootProps()} className={styles.dropzone}>
                    <input {...getInputProps()} />
                    <UploadIcon fontSize="small" />
                    <Typography className={styles.title} variant="subtitle1">
                      Supported Formats
                    </Typography>
                    <Typography className={styles.text} variant="body2">
                      WAVE (WAV), MP3, AIFF, AAC, Ogg Vorbis, FLAC, ASF, MP4
                    </Typography>
                    <Typography className={styles.title} variant="subtitle1">
                      Upload Limits
                    </Typography>
                    <Typography className={styles.text} variant="body2">
                      • File Size: Maximum of 500 MB per file
                    </Typography>
                    <Typography className={styles.text} variant="body2">
                      • Audio Length: Maximum of 5 hours per file
                    </Typography>
                    <Typography className={styles.text} variant="body2">
                      For more detailed information about our upload policies and troubleshooting
                      tips, please visit our
                      <Link
                        href="https://helpdesk.sembly.ai/hc/en-us/articles/8901816805649"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        Help Center
                      </Link>
                      .
                    </Typography>
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={isDropzoneDisable}
                      aria-label="Select file to upload"
                      onClick={open}
                    >
                      <Box p={0.5} component="span">
                        Select file
                      </Box>
                    </Button>
                  </div>

                  {isAttachmentDecoded ? (
                    <Box p={4} textAlign="center">
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    <>
                      {errors ? (
                        <Typography className={styles.errorBox}>{errors}</Typography>
                      ) : (
                        Boolean(fileName) && (
                          <>
                            <List disablePadding>
                              <ListItem
                                button
                                className={styles.listItemRoot}
                                onClick={handleClickOnDeleteAttachment}
                              >
                                <ListItemIcon className={styles.listItemIcon}>
                                  <AttachmentIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                  className={styles.listItemText}
                                  classes={{ primary: styles.listItemName }}
                                >
                                  {fileName}
                                </ListItemText>
                                <IconButton
                                  className={styles.deleteIcon}
                                  edge="end"
                                  aria-label="delete"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </ListItem>
                            </List>
                            <Typography className={styles.subTitle} variant="h6">
                              Meeting Title *
                            </Typography>
                            <TextField
                              required
                              hiddenLabel
                              fullWidth
                              size="small"
                              defaultValue={meetingTitle}
                              inputProps={{ maxLength: 255 }}
                              InputProps={{
                                classes: {
                                  input: styles.textFieldInput,
                                  root: styles.textFieldRoot,
                                },
                              }}
                              onChange={handleChangeMeetingTitle}
                            />
                            <Box mt={2}>
                              <LanguageSelects
                                primaryLanguage={primaryLang}
                                additionalLanguage={additionalLang}
                                formGroupTitleProps={{ variant: 'body1' }}
                                onChange={(primaryLang, additionalLang) => {
                                  setMeetingLanguages({ additionalLang, primaryLang });
                                }}
                              />
                            </Box>
                          </>
                        )
                      )}
                    </>
                  )}
                </>
              )}

              <div className={styles.buttonsContainer}>
                <Button
                  disableElevation
                  variant="outlined"
                  aria-label="Cancel upload"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Box mr={1} />
                {!isProcessing && (
                  <Button
                    disableElevation
                    type="submit"
                    color="primary"
                    variant="contained"
                    aria-label="Upload file"
                    disabled={!fileName}
                  >
                    Upload
                  </Button>
                )}
              </div>
            </fieldset>
          </form>
        </DialogContent>
      </>
    );
  /* #endregion */

  return (
    <Dialog
      open
      fullWidth
      keepMounted
      disableEscapeKeyDown
      maxWidth="sm"
      scroll="paper"
      fullScreen={isSmallScreen}
      TransitionComponent={TransitionSlide}
      onClose={handleClose}
    >
      {isPersonalPlan ? (
        isAuthorizedToUpload ? (
          renderDialogContent()
        ) : (
          <>
            <Box className={styles.title}>
              <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
              px={4}
              py={4}
              pt={0}
            >
              {loading ? (
                <Box p={10} mt={3} mb={4}>
                  <CircularProgress size={24} color="primary" />
                </Box>
              ) : (
                <LimitExceededMessage onClose={handleClose}>
                  <span>You've exceeded your time limit of Sembly audio uploads.</span>
                  <br />
                  <span>Upgrade now to upload more audio.</span>
                </LimitExceededMessage>
              )}
            </Box>
          </>
        )
      ) : (
        renderDialogContent()
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  description: {
    paddingTop: theme.spacing(1.5),
    fontSize: theme.typography.body2.fontSize,
  },
  content: {
    width: '100%',
    padding: theme.spacing(0, 4, 4),
  },
  subHeader: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  remaining: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
  },
  linearProgress: {
    height: 4,
    width: '100%',
    background: theme.palette.grey[200],
  },
  progressBar: {
    backgroundColor: theme.palette.primary.main,
  },
  exceededBar: {
    backgroundColor: theme.palette.highlight.light,
  },
  progressBarPaused: {
    backgroundColor: theme.palette.grey[300],
  },
  uploading: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.black,
  },
  progress: {
    width: theme.spacing(5),
    marginLeft: theme.spacing(1),
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.common.black,
  },
  dot: {
    '&::before': {
      content: "''",
      display: 'inline-block',
      margin: theme.spacing(0, 1),
      position: 'relative',
      top: '-3px',
      width: '3px',
      height: '3px',
      backgroundColor: theme.palette.common.black,
      borderRadius: '50%',
    },
  },
  subTitle: {
    fontSize: theme.typography.body1.fontSize,
    margin: theme.spacing(3, 0, 1),
  },
  text: {
    fontSize: theme.typography.body2.fontSize,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  dropzone: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    border: `1px dashed ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
  },
  helperText: {
    textAlign: 'center',
  },
  formContainer: {
    '&:disabled': {
      pointerEvents: 'none',
    },
  },
  textFieldRoot: {
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  textFieldInput: {
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(2),
  },
  listItemRoot: {
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(0, 1, 0, 1.5),
    borderRadius: theme.spacing(0.5),
  },
  listItemIcon: {
    minWidth: theme.spacing(3),
  },
  listItemText: {
    maxWidth: 'calc(100vw - 138px)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '465px',
    },
  },
  listItemName: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  deleteIcon: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(-0.5),
  },
  errorBox: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.fault.main,
    color: theme.palette.fault.contrastText,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  warningBox: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#D9F0FE',
    color: '#2267B8',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

export default UploadMeetingModal;
