import { useState, useEffect, useRef } from 'react';
import { sortBy } from 'lodash';
import {
  NetworkStatus,
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import QuestionMarkIcon from '@material-ui/icons/HelpOutline';
import TodayIcon from '@material-ui/icons/Today';
// Lib Queries and Types
import query from '../graphql/queries/MyChatsItemContainerQuery.graphql';
import chatQuery from '../graphql/queries/AIAssociateChat.graphql';
import askQuestionMutation from '../graphql/mutations/AddQuestionToChat.graphql';
import addInsightMutation from '../graphql/mutations/AddInsightToChat.graphql';
import addMeetingsMutation from '../graphql/mutations/AddMeetingsToChat.graphql';
import chatSubscription from '../graphql/subscriptions/AiAssociateChatSubscription.subscription';
import {
  AIAssociateChat,
  AIAssociateChatVariables,
  AddInsightToChat,
  AddInsightToChatVariables,
  AddMeetingsToChat,
  AddMeetingsToChatVariables,
  AddQuestionToChat,
  AddQuestionToChatVariables,
  GenericChatArtifact,
  GraphError,
  MyChatsItemContainerQuery,
  MyChatsItemContainerQueryVariables,
} from '../types';
// Lib Shared
import { ChatItem, ChatItemsSkeleton, GenericDialog, ChatInputBox } from '../components';
import { Creation } from '../icons';
import { MeetingsListContainer } from './MeetingsListContainer';
import { WorkstreamInsightsListContainer } from './WorkstreamInsightsListContainer';
import { useIntersectionObserver } from '../hooks';

export interface MyChatsItemContainerProps {
  chatId: string;
  chatWorkstreamId: string | null;
  onResponseError?: (error: GraphError) => void;
  onClickOnChatArtefact?: (data: GenericChatArtifact) => void;
}

/**
 * Renders a list of questions and answers for a given chat
 */
export const MyChatsItemContainer: React.FC<MyChatsItemContainerProps> = ({
  chatId,
  chatWorkstreamId,
  children,
  onResponseError = () => null,
  onClickOnChatArtefact = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const client = useApolloClient();

  const isCooldownRef = useRef(false);
  const lastChatElementRef = useRef<HTMLDivElement>(null);

  const [loadMoreButtonRef, setLoadMoreButtonRef] = useState<HTMLButtonElement | null>(null);
  const [optionsButtonAnchorEl, setOptionsButtonAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenWorkstreamInsightList, setIsOpenWorkstreamInsightList] = useState(false);
  const [isOpenMeetingsList, setIsOpenMeetingsList] = useState(false);

  const nextPageButtonObserver = useIntersectionObserver(loadMoreButtonRef);

  const { data, fetchMore, networkStatus } = useQuery<
    MyChatsItemContainerQuery,
    MyChatsItemContainerQueryVariables
  >(query, {
    variables: { chatId, page: 1 },
    notifyOnNetworkStatusChange: true,
  });

  const { data: chatData } = useQuery<AIAssociateChat, AIAssociateChatVariables>(chatQuery, {
    variables: { chatId },
    fetchPolicy: 'cache-only',
  });

  const [askQuestion, { loading: isAskingQuestion }] = useMutation<
    AddQuestionToChat,
    AddQuestionToChatVariables
  >(askQuestionMutation);

  const [addInsight, { loading: isAddingInsight }] = useMutation<
    AddInsightToChat,
    AddInsightToChatVariables
  >(addInsightMutation);

  const [addMeeting, { loading: isAddingMeeting }] = useMutation<
    AddMeetingsToChat,
    AddMeetingsToChatVariables
  >(addMeetingsMutation);

  useSubscription(chatSubscription);
  /* #endregion */

  /* #region  Handlers */
  const handleAskQuestion = async (question: string) => {
    const response = await askQuestion({
      variables: { chatId, question },
      onCompleted(result) {
        if (!!result.addQuestionToChat?.chatHistoryItem) {
          client.cache.updateQuery<MyChatsItemContainerQuery, MyChatsItemContainerQueryVariables>(
            { query, variables: { chatId } },
            (data) => {
              if (!data || !data.chatHistoryItemsPaginated?.objects) return data;
              return {
                ...data,
                chatHistoryItemsPaginated: {
                  ...data.chatHistoryItemsPaginated,
                  objects: [
                    result.addQuestionToChat?.chatHistoryItem!,
                    ...data.chatHistoryItemsPaginated?.objects,
                  ],
                },
              };
            },
          );
          setTimeout(() => {
            // Scroll to the bottom of the chat
            lastChatElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 100);
        }
      },
    });

    if (response.data?.addQuestionToChat?.success) {
    } else {
      onResponseError(response.data?.addQuestionToChat?.errors);
    }
  };

  const handleAddMeetings = async (meetingIds: string[]) => {
    handleCloseMenu();
    const response = await addMeeting({
      variables: { chatId, meetingIds },
      onCompleted(result) {
        if (!!result.addMeetingsToChat?.chatHistoryItems?.length) {
          const addedMeetings = result.addMeetingsToChat.chatHistoryItems;
          const existingMeetings = data?.chatHistoryItemsPaginated?.objects ?? [];
          client.cache.updateQuery<MyChatsItemContainerQuery, MyChatsItemContainerQueryVariables>(
            { query, variables: { chatId } },
            (data) => {
              if (!data || !data.chatHistoryItemsPaginated) return data;
              return {
                ...data,
                chatHistoryItemsPaginated: {
                  ...data.chatHistoryItemsPaginated,
                  objects: [...existingMeetings, ...addedMeetings],
                },
              };
            },
          );
          setTimeout(() => {
            // Scroll to the bottom of the chat
            lastChatElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 100);
        }
      },
    });

    if (!response.data?.addMeetingsToChat?.success) {
      onResponseError(response.data?.addMeetingsToChat?.errors);
    } else {
      setIsOpenMeetingsList(false);
    }
  };

  const handleRequestMoreItems = async () => {
    const currentPage = data?.chatHistoryItemsPaginated?.page || 1;

    if (!isCooldownRef.current && !isLoadingMore) {
      isCooldownRef.current = true;
      await fetchMore({ variables: { chatId, page: currentPage + 1 } });
      setTimeout(() => (isCooldownRef.current = false), 100);
    }
  };

  const handleCloseMenu = () => {
    setOptionsButtonAnchorEl(null);
  };

  const handleClickOnOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionsButtonAnchorEl(event.currentTarget);
  };

  const handleAddWorkstreamInsight = async (insightId: string) => {
    handleCloseMenu();

    const response = await addInsight({
      variables: { chatId, insightId },
      onCompleted(result) {
        if (result.addInsightToChat?.chatHistoryItem) {
          client.cache.updateQuery<MyChatsItemContainerQuery, MyChatsItemContainerQueryVariables>(
            { query, variables: { chatId } },
            (data) => {
              if (!data || !data.chatHistoryItemsPaginated) return;
              return {
                chatHistoryItemsPaginated: {
                  ...data.chatHistoryItemsPaginated,
                  objects: [
                    ...(data.chatHistoryItemsPaginated?.objects || []),
                    result.addInsightToChat?.chatHistoryItem!,
                  ],
                },
              };
            },
          );
          setTimeout(() => {
            // Scroll to the bottom of the chat
            lastChatElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 100);
        }
      },
    });

    if (!response.data?.addInsightToChat?.success) {
      onResponseError(response.data?.addInsightToChat?.errors);
    } else {
      setIsOpenWorkstreamInsightList(false);
    }
  };

  const handleOpenWorkstreamInsightList = () => {
    handleCloseMenu();
    setIsOpenWorkstreamInsightList(true);
  };

  const handleOpenMeetingsList = () => {
    handleCloseMenu();
    setIsOpenMeetingsList(true);
  };

  const handleCloseMeetingsList = () => {
    setIsOpenMeetingsList(false);
  }
  /* #endregion */

  /* #region  Render Helpers */
  const hasHistory = !!data?.chatHistoryItemsPaginated?.objects?.length;
  const historyData = data?.chatHistoryItemsPaginated?.objects || [];
  const sortedHistoryData = sortBy(historyData, (o) => parseInt(o.id, 10));
  const hasMore = data?.chatHistoryItemsPaginated?.hasNext || false;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoadingInitial = networkStatus === NetworkStatus.loading;
  const hasInitialDataFetched = networkStatus === NetworkStatus.ready;
  const isChatInProcessing = chatData?.chat?.isInProcessing || false;
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    if (hasInitialDataFetched) {
      // Scroll to the bottom of the chat
      lastChatElementRef.current?.scrollIntoView({ block: 'end' });
    }
  }, [hasInitialDataFetched]);

  useEffect(() => {
    if (nextPageButtonObserver.isIntersecting && hasMore && !isLoadingMore) {
      handleRequestMoreItems();
    }
  });
  /* #endregion */

  // Render loading indicator if the data is not ready
  if (isLoadingInitial) {
    return (
      <Box position="relative" width="100%" overflow="hidden" paddingTop={4}>
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <div className={styles.root}>
        {children}
        <div>
          {hasHistory ? (
            <div className={styles.list}>
              {hasMore && !isLoadingMore && (
                <Button
                  variant="outlined"
                  className={styles.loadMoreBtn}
                  ref={setLoadMoreButtonRef}
                  onClick={handleRequestMoreItems}
                >
                  Load more
                </Button>
              )}

              {isLoadingMore && <ChatItemsSkeleton count={10} />}

              {sortedHistoryData.map((chat) => (
                <ChatItem data={chat} key={chat.id} onClickOnArtefact={onClickOnChatArtefact} />
              ))}

              {isLoadingMore && (
                <Box textAlign="center" m={4}>
                  <CircularProgress size={18} />
                </Box>
              )}
              <div style={{ marginTop: 100 }} ref={lastChatElementRef} />
            </div>
          ) : (
            <Box mt={6} textAlign="center">
              <Typography paragraph variant="h6" color="textPrimary">
                There are no chats available
              </Typography>
            </Box>
          )}
        </div>
        <div className={styles.footer}>
          <Box display="flex">
            <Box flex={1}>
              <ChatInputBox
                isAddingInsight={isAddingInsight}
                isChatInProcessing={isChatInProcessing}
                isAddingMeeting={isAddingMeeting}
                isAskingQuestion={isAskingQuestion}
                onClickOnOptions={handleClickOnOptions}
                onSubmit={handleAskQuestion}
              />
            </Box>
            <Hidden smDown>
              <IconButton
                target="_blank"
                href="https://helpdesk.sembly.ai/hc/en-us/articles/28730891574161-How-to-use-Semblian-2-0-My-Chats"
                rel="noopener noreferrer"
                className={styles.iconButton}
              >
                <QuestionMarkIcon />
              </IconButton>
            </Hidden>
          </Box>
          <Typography variant="body2" color="textSecondary" className={styles.notice}>
            AI-generated content, use judiciously
          </Typography>
        </div>
      </div>
      {/* Begin: Dialogs */}
      {optionsButtonAnchorEl && (
        <Menu
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          getContentAnchorEl={null}
          anchorEl={optionsButtonAnchorEl}
          open={Boolean(optionsButtonAnchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleOpenWorkstreamInsightList}>
            <ListItemIcon className={styles.icon}>
              <Creation fontSize="small" color="action" />
            </ListItemIcon>
            <Typography variant="inherit">Add Insight to chat</Typography>
          </MenuItem>
          <MenuItem onClick={handleOpenMeetingsList}>
            <ListItemIcon className={styles.icon}>
              <TodayIcon fontSize="small" color="action" />
            </ListItemIcon>
            <Typography variant="inherit">Add Meeting to chat</Typography>
          </MenuItem>
        </Menu>
      )}
      {isOpenWorkstreamInsightList && (
        <GenericDialog
          title="Add Insights to chat"
          dialogProps={{
            fullScreen: isSmallScreen,
            fullWidth: true,
            maxWidth: 'lg',
            scroll: 'paper',
          }}
          onClose={() => setIsOpenWorkstreamInsightList(false)}
        >
          <DialogContent dividers className={styles.dialogContent}>
            <WorkstreamInsightsListContainer
              processing={isAddingInsight}
              workstreamId={chatWorkstreamId}
              onSelect={handleAddWorkstreamInsight}
            />
          </DialogContent>
        </GenericDialog>
      )}
      {isOpenMeetingsList && (
        <GenericDialog
          title="Meetings"
          dialogProps={{
            fullScreen: isSmallScreen,
            fullWidth: true,
            maxWidth: 'lg',
            scroll: 'paper',
          }}
          onClose={() => setIsOpenMeetingsList(false)}
        >
          <DialogContent dividers className={styles.dialogContent}>
            <MeetingsListContainer
              chatId={chatId}
              processing={isAddingMeeting}
              onCancel={handleCloseMeetingsList}
              onSubmit={handleAddMeetings}
            />
          </DialogContent>
        </GenericDialog>
      )}
      {/* End: Dialogs */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(5),
  },
  loadMoreBtn: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
  },
  icon: {
    color: theme.palette.accents.purple,
  },
  iconButton: {
    width: 52,
    height: 52,
    marginLeft: theme.spacing(0.5),
  },
  footer: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 0),
  },
  dialogContent: {
    padding: theme.spacing(0, 2),
  },
  notice: {
    marginLeft: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}));

export default MyChatsItemContainer;
